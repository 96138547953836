import { ContentType, HttpClient, RequestParams } from '../http-client';

export class Cbt<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getCompanyCbt = (
    query: {
      title?: string;
      description?: string;
      rank?: string;
      status?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/company/questionnaire`,
      method: 'GET',
      secure: true,
      ...(query && { query }),
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyCbtMasterQuestion = (
    query: {
      rank?: string;
      vessel_type?: string;
      type?: string;
      special_skill?: string;
      area?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/company/questionnaire/master`,
      method: 'GET',
      secure: true,
      ...(query && { query }),
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  createCbt = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/questionnaire`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCbtById = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/questionnaire/${id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCbtMasterById = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/questionnaire/master/${id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  patchCbtMasterById = (id: string, data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/questionnaire/master/${id}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  uploadQuestionImage = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/questionnaire/question/file`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });

  patchCbtById = (id: string, data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/questionnaire/${id}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  changeCbtStatusById = (
    id: string,
    data: string,
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/company/questionnaire/${id}/status`,
      method: 'PATCH',
      body: {
        status: data,
      },
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  retakeCbtByPublicId = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/retake/${id}`,
      method: 'PATCH',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getApplicantCbtResult = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/applicant/${id}/cbt`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
